<template>
  <div id="audit-experience">
    <el-row type="flex" justify="space-between" align="center">
      <el-col :xs="2" :sm="2" :md="6" style="display: flex; align-items: center;">
        <router-link :to="{ name: 'Experiences' }">
          <el-button round style="width: 100%;" class="hidden-sm-and-down">
            <i class="fa fa-chevron-left" style="margin-right: 10px"></i>
            Back to experiences
          </el-button>
          <el-button type="text" class="hidden-md-and-up">
            <i class="fa fa-chevron-left" style="margin-right: 10px"></i>
          </el-button>
        </router-link>
      </el-col>
      <el-col :xs="22" :sm="22">
        <ModerateExperienceInfo :experience="experience" />
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="24">
        <div class="experiences-card">
          <el-card shadow="always" class="mb-10">
            <el-row :gutter="10" class="mt-30 mb-30" type="flex" justify="center">
              <el-col :xs="14" :sm="18">
                <el-input
                  type="text"
                  placeholder="Search @handle"
                  style="max-width: 100%;"
                  v-model.trim="userHandle"
                  @change="loadAuditLog"
                  @input="handleSearchInput"
                >
                <div slot="append">
                  <el-button v-if="userHandle.length" type="primary" icon="el-icon-circle-close" @click="clearUserHandle"></el-button>
                </div>

              </el-input>
              </el-col>
              <el-col :xs="4" :sm="4">
                <el-button
                  round
                  @click="loadAuditLog"
                  type="success"
                >
                  Search
                </el-button>
              </el-col>
            </el-row>
          </el-card>
        </div>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="24">
        <el-tabs v-model="activeTab" stretch>
          <el-tab-pane name="approved" class="left">
            <span class="success" slot="label">Approved</span>
            <el-table class="audit-log" :data="approvedList">
              <el-table-column width="104">
                <template slot-scope="scope">
                  <el-image :src="scope.row.profilePicture" style="border-radius: 50%;" />
                </template>
              </el-table-column>
              <el-table-column sortable prop="userHandle" label="User Handle" width="210"></el-table-column>
              <el-table-column sortable prop="dateReceived" label="Received" width="250"></el-table-column>
              <el-table-column sortable prop="moderatorHandle" label="Moderator" width="210"></el-table-column>
              <el-table-column sortable prop="moderatedTime" label="Moderated" width="250"></el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane name="rejected">
            <span class="danger" slot="label">Rejected</span>
            <el-table class="audit-log" :data="declinedList">
              <el-table-column width="104">
                <template slot-scope="scope">
                  <el-image :src="scope.row.profilePicture" style="border-radius: 50%;" />
                </template>
              </el-table-column>
              <el-table-column sortable prop="userHandle" label="User Handle" width="210"></el-table-column>
              <el-table-column sortable prop="dateReceived" label="Received" width="250"></el-table-column>
              <el-table-column sortable prop="moderatorHandle" label="Moderator" width="210"></el-table-column>
              <el-table-column sortable prop="moderatedTime" label="Moderated" width="250"></el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ModerateExperienceInfo from '@/components/experiences/ModerateExperienceInfo.vue'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment-timezone'
import * as R from 'ramda'

const timezone = moment.tz.guess(true)

const dateFormat = 'YYYY/MM/DD - hh:mm A (Z)'

const hasTwitterActivity = R.has('twitter_activity')
    const hasFacebookActivity = R.has('facebook_activity')
    const hasInstagramActivity = R.has('instagram_activity')
    const isValidActivity = R.allPass([R.complement(R.isNil), R.is(Object), R.complement(R.isEmpty)])
    const isValidTwitterActivity = R.allPass([
    hasTwitterActivity,
    R.pipe(R.prop('twitter_activity'), isValidActivity)
    ])

    const isValidFacebookActivity = R.allPass([
    hasFacebookActivity,
    R.pipe(R.prop('facebook_activity'), isValidActivity)
    ]
    )

    const isValidInstagramActivity = R.allPass([
    hasInstagramActivity,
    R.pipe(R.prop('instagram_activity'), isValidActivity)
    ]
    )

  const getInstagramTimestamp = R.cond([
    [R.has('messageEvent'), R.pipe(R.path(['messageEvent', 'timestamp']), Number)],
    [R.T, R.pipe(R.pathOr('', ['commentEvent', 'time']), Number, R.multiply(1000))]
  ])
  const getInstagramProfilePic = R.cond([
    [R.has('messageEvent'), R.path(['messageEvent', 'sender', 'profile_pic'])],
    [R.T, R.pathOr('', ['commentEvent', 'changes', 0, 'value', 'from', 'profile_pic'])]
  ])
    
const extractProfilePicture = R.cond([
    [isValidTwitterActivity, R.path(['twitter_activity', 'user', 'profile_image_url_https'])],
    [isValidFacebookActivity, R.path(['facebook_activity', 'changes', 0, 'value', 'photo'])],
    [isValidInstagramActivity, R.pipe(R.prop('instagram_activity'), getInstagramProfilePic)],
    [R.T, R.always('')]
    ])

const extractDateReceived = R.cond([
    [isValidTwitterActivity, R.path(['twitter_activity', 'created_at'])],
    [isValidFacebookActivity, R.pipe(R.path(['facebook_activity', 'time']), Number, R.multiply(1000))],
    [isValidInstagramActivity, R.pipe(R.prop('instagram_activity'), getInstagramTimestamp)],
    [R.T, R.always('')]
    ])

const auditLogToTable = item => {
    return {
  profilePicture: extractProfilePicture(item),
  userHandle: item.user_handle,
  dateReceived: moment(new Date(extractDateReceived(item)))
    .tz(timezone)
    .format(dateFormat),
  moderatorHandle: item.moderator_handle,
  moderatedTime: moment(item.moderated_at)
    .tz(timezone)
    .format(dateFormat)
}
}

// const auditLogToTable = item => ({
//   profilePicture: item.facebook_activity.changes[0].value.photo,
//   userHandle: item.user_handle,
//   dateReceived: moment(Number(item.facebook_activity.time * 1000)).tz(timezone).format(dateFormat),
// //     .tz(timezone)
// //     .format(dateFormat),
//   moderatorHandle: item.moderator_handle,
//   moderatedTime: moment(item.moderated_at)
//     .tz(timezone)
//     .format(dateFormat)
// })

export default {
  name: 'AuditExperience',

  props: ['id'],

  components: {
    ModerateExperienceInfo
  },

  data () {
    return {
      loading: false,
      experience: { id: '' },
      auditLog: [],
      userHandle: '',
      activeTab: 'approved'
    }
  },

  computed: {
    ...mapGetters(['isAuthenticated']),

    approvedList () {
      return this.auditLog
        .filter(item => item.outcome === 'approved')
        .map(auditLogToTable)
    },

    declinedList () {
      return this.auditLog
        .filter(item => item.outcome === 'declined')
        .map(auditLogToTable)
    }
  },

  mounted () {
    this.loadExperience()
    this.loadAuditLog()
  },

  methods: {
    ...mapActions(['validateToken']),

    async loadExperience () {
      if (!this.isAuthenticated) return
      this.loading = true

      try {
        const response = await this.$ky.get(`${process.env.VUE_APP_API_URL}/experiences/${this.$route.params.id}`)

        if (!response) {
          this.$notify({
            type: 'error',
            title: 'A network error occured',
            message: 'Please refresh your page.',
            duration: 0
          })
          return
        }

        if (response.status === 200) {
          this.$set(this, 'experience', response.data.data)
        }

        if (response.status === 403) {
          this.$notify({
            type: 'error',
            title: 'Not Authorized',
            message: 'You are not authorized to access this page.'
          })
          this.experience = {}
          this.validateToken()
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },

    async loadAuditLog () {
      if (!this.isAuthenticated) return
      this.loading = true

      try {
        let url = `${process.env.VUE_APP_API_URL}/auditlog/${this.$route.params.id}`

        if (this.userHandle) {
          url += `?userHandle=${this.userHandle.replace('@', '')}`
        }

        const response = await this.$ky.get(url)

        if (response) {
          if (response.status === 200) {
            this.auditLog = response.data.data
          }

          if (response.status === 403) {
            this.$notify({
              type: 'error',
              title: 'Not Authorized',
              message: 'You are not authorized to access this page.'
            })
            this.auditLog = []
            this.validateToken()
          }
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },

    /**
     * Clears the search field
     * And reloads the audit log table
     */
    clearUserHandle () {
      this.userHandle = ''
      this.loadAuditLog()
    },

    /**
     * Reloads the audit log when the search text is cleared
     */
    handleSearchInput () {
      if (this.userHandle === '') {
        this.loadAuditLog()
      }
    }
  }
}
</script>

<style lang="scss">
#audit-experience {
  .experiences-card .el-card {
    background-color: rgb(15, 19, 45);
    border: none;
    color: #fff;

    .el-card__body {
      display: flex;
      padding-left: 0;
      align-items: center;
      justify-content: center;

      @media (max-width: 765px) {
        margin-left: -20px;
      }
    }

    .el-input {
      input {
        background-color: rgb(15, 19, 45);
        color: gray;
        line-height: 1;
        transition: border-color 0.2s ease;

        &::placeholder {
          color: rgb(117, 117, 117);
        }
      }

      .el-input-group__append, .el-input-group__prepend {
        background-color: transparent;
        transition: border-color 0.2s ease;

        .el-button {
          border: none;
          padding: 15px 13px;
        }
      }

      &:focus-within {
        .el-input-group__append, .el-input-group__prepend {
          border-color:rgb(76, 163, 253);
        }
      }
    }

  }

  .audit-log.el-table {
    background-color: transparent;
    color: white;

    &::before {
      display: none;
    }

    th, tr {
      background-color: transparent;
    }

    td, th {
      border-bottom: none;
    }

    tbody tr {
      &:nth-child(odd) {
        background-color: #0f132d;
      }

      &:hover > td {
        background-color: #606266;
      }
    }
  }
}
</style>
